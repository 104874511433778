import { Component } from '@angular/core';
import { MAIN_MENU } from '../../data/items-main-menu.data';
import { MenuItem } from '../../models/menu-item.model';
import { SlideMenuService } from '../../services/slide-menu.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {

  public menuItems: Array<MenuItem> = MAIN_MENU;

  public color: string = 'text-primary';

  constructor(
    public slideMenuService: SlideMenuService
  ) { }

}
