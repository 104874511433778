import { Component } from '@angular/core';
import { SlideMenuService } from '../../services/slide-menu.service';
import { MAIN_MENU } from '../../data/items-main-menu.data';
import { MenuItem } from '../../models/menu-item.model';
import { SpinnerService } from '../../services/spinner.service';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrl: './main-layout.component.scss'
})
export class MainLayoutComponent {

  public menuItems:MenuItem[] = MAIN_MENU;

  constructor(
    public slideMenuService: SlideMenuService,
    public spinnerService: SpinnerService
  ) { }

}
