<div 
    class="container-fluid position-fixed bg-transparent-dark" 
    style="top: 0px; left: 0px; height: 100vh; z-index: 1024 !important"
>
    <div class="row h-100">

        <a class="col-5 col-sm-5 col-md-8 col-lg-9 col-xl-10" (click)="slideMenuService.slideMenuOpened = false">
    
        </a>

        <div class="col-7 col-sm-7 col-md-4 col-lg-3 col-xl-2 h-100 bg-white pt-4 px-0 mb-5" style="height: 100vh;">
            <div class="row">
                <div class="col-12 pt-3">
                    <div class="row">

                        <ng-template
                            ngFor 
                            let-menuItem 
                            [ngForOf]="menuItems">

                            <div class="col-12 px-3 py-2" *ngIf="menuItem.slideMenu">

                                <a
                                    [routerLink]="menuItem.link"
                                    (click)="slideMenuService.slideMenuOpened = false"
                                    class="text-decoration-none text-primary btn btn-link w-100 d-flex justify-content-between align-items-center">
                                    <b>{{ menuItem.title }}</b>
                                    <i class="material-icons ms-auto" style="font-size: 15px !important;">arrow_forward_ios</i>
                                </a>

                            </div>

                        </ng-template>

                    </div>
                </div>
            </div>
        </div>
    
    </div>
</div>