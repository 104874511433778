import { NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { environment } from '../environments/environment';


import { AppRoutingModule } from './app-routing.module';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';

import { AppComponent } from './app.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { ToastsComponent } from './components/toasts/toasts.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { SlideMenuComponent } from './components/slide-menu/slide-menu.component';
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import { provideHttpClient, withFetch } from "@angular/common/http";
import { SpinnerComponent } from './components/spinner/spinner.component';
import { SpinnerService } from './services/spinner.service';

@NgModule({
  declarations: [
    AppComponent,
    MainLayoutComponent,
    ToastsComponent,
    HeaderComponent,
    FooterComponent,
    SlideMenuComponent,
    MenuItemComponent,
    SpinnerComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FontAwesomeModule,
    NgbToastModule,
    HttpClientModule,
    HttpClientJsonpModule,
    NgxGoogleAnalyticsModule.forRoot(environment.google.gaApiKey),
  ],
  providers: [
    provideClientHydration(),
    provideHttpClient(withFetch()),
    SpinnerService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
