import { MenuItem } from "../models/menu-item.model";

export const MAIN_MENU: Array<MenuItem> = [
    {
        // icon: '',
        link: '/',
        title: 'Inicio',
        class: '',
        href: false,
        slideMenu: true
    },
    {
        // icon: '',
        link: '/apply-for-credit',
        title: 'Solicita tu crédito',
        class: '',
        href: false,
        slideMenu: true
    },
    {
        // icon: '',
        link: 'tel:5552665387',
        title: '55-5266-5387',
        class: 'd-none d-xl-block',
        href: true,
        slideMenu: false
    },
    {
        icon: 'phone',
        link: 'tel:5552665387',
        class: 'd-none d-lg-block',
        title: '',
        href: true,
        slideMenu: false
    },
]