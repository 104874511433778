import { Component, Input } from '@angular/core';
import { MenuItem } from '../../models/menu-item.model';
import { SlideMenuService } from '../../services/slide-menu.service';

@Component({
  selector: 'app-slide-menu',
  templateUrl: './slide-menu.component.html',
  styleUrl: './slide-menu.component.scss'
})
export class SlideMenuComponent {

  @Input() menuItems:MenuItem[] = [];

  constructor(
    public slideMenuService: SlideMenuService
  ) { }

}
