import { Component } from '@angular/core';
import { 
  faTwitter, 
  faFacebookF, 
  faInstagram, 
  faYoutube,
  faTiktok
} from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {

  faTwitter = faTwitter;
  faFacebook = faFacebookF;
  faInstagram = faInstagram;
  faYoutube = faYoutube;
  faTiktok = faTiktok;

}
