<!-- @if (spinnerService.loading) {
  <app-spinner />
}  -->
<app-header></app-header>
<router-outlet></router-outlet>
<app-footer></app-footer>
<app-slide-menu 
  *ngIf="slideMenuService.slideMenuOpened"
  [menuItems]="menuItems">
</app-slide-menu>
