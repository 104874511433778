<div 
    style="top: 0px; z-index: 10024;"
    class="position-fixed bg-white w-100 h-100">

    <div class="d-flex align-items-center justify-content-center w-100 h-100">
        <div class="text-center">
            <div 
              class="spinner-border text-primary" 
              style="width: 5rem; height: 5rem;">
            </div>
            <div class="text-center">
                <h3><b>{{ spinnerService.message }}</b></h3>
            </div>
        </div>
    </div>
</div>