<header class="shadow">
  <div class="container py-3">
      <div class="row">
  
          <div class="col-6 col-lg-2 d-flex align-items-center">
              <a routerLink="/">
                  <img src="./assets/media/img/logos/maltia-logo-blue.png" width="80%" class="d-none d-lg-block" alt="maltia-logo-blue">
              </a>

              <a routerLink="/">
                  <img src="./assets/media/img/logos/maltia-logo-blue.png" width="60%" class="d-block d-lg-none" alt="maltia-logo-blue">
              </a>
              
          </div>

          <div class="col-6 d-block d-lg-none">
              <div class="row">
                  <div class="col-12 d-flex justify-content-end">

                      <a class="btn btn-link text-decoration-none text-primary d-flex align-items-center">
                          <i class="material-icons">phone</i>
                      </a>

                      <a (click)="slideMenuService.slideMenuOpened = true" class="btn btn-link text-decoration-none text-white d-flex align-items-center justify-content-center bg-primary rounded-circle p-2">
                          <i class="material-icons">menu</i>
                      </a>

                  </div>
              </div>
          </div>
  
          <div class="col-10 d-none d-lg-block">
              <div class="row">
                  <div class="col-12 d-flex justify-content-end">

                      <ng-template
                          ngFor 
                          let-menuItem 
                          [ngForOf]="menuItems">
                          <app-menu-item
                              [colorClass]="color"
                              [menuItem]="menuItem">
                          </app-menu-item>
                      </ng-template>
  
                  </div>
              </div>
          </div>
      </div>
  </div>
</header>