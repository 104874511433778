<a
    *ngIf="!menuItem!.href"
    [routerLink]="menuItem!.link"
    [ngClass]="[colorClass, menuItem!.class]"
    class="btn btn-link text-decoration-none fw-500 letter-spacing-10">
    {{ menuItem!.title }}
    <i 
        *ngIf="menuItem!.icon"
        class="material-icons">
        {{ menuItem!.icon }}
    </i>
</a>

<a
    *ngIf="menuItem!.href"
    href="{{menuItem!.link}}"
    [ngClass]="[colorClass, menuItem!.class]"
    class="btn btn-link text-decoration-none fw-500 letter-spacing-10">
    {{ menuItem!.title }}
    <i 
        *ngIf="menuItem!.icon"
        class="material-icons">
        {{ menuItem!.icon }}
    </i>
</a>