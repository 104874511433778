<footer class="bg-secondary f-kanit text-white">
  <div class="container py-5">

      <div class="row mb-5">
          <div class="col-12">
              <img src="./assets/media/img/logos/maltia-logo-white.png" width="10%" class="d-none d-lg-block" alt="logo-maltia">
              <img src="./assets/media/img/logos/maltia-logo-white.png" width="20%" class="d-none d-md-block d-lg-none" alt="logo-maltia">
              <img src="./assets/media/img/logos/maltia-logo-white.png" width="40%" class="d-block d-md-none" alt="logo-maltia">
          </div>
      </div>

      <div class="row">

          <div class="col-12 col-md-6 col-lg-3 mb-5">
              <div class="row">

                  <!-- <div class="col-12 p-2 fw-500">
                      Preguntas frecuentes
                  </div> -->

                  <div class="col-12 p-2 fw-500">
                      <a 
                          routerLink="/terms/privacy-policy" 
                          class="text-decoration-none text-white"
                      >
                          Política y Aviso de privacidad
                      </a>
                  </div>

                  <div class="col-12 p-2 fw-500">
                      <a 
                          routerLink="/terms/terms-and-conditions" 
                          class="text-decoration-none text-white"
                      >
                          Términos y condiciones
                      </a>
                  </div>

                  <!-- <div class="col-12 p-2 fw-500">
                      <a 
                          routerLink="/requirements" 
                          class="text-decoration-none text-white"
                      >
                      Requisitos trámites
                      </a>
                  </div> -->

              </div>
          </div>

          <div class="col-12 col-md-6 col-lg-3 mb-5">
            <div class="row">
                <div class="col-12 p-2 fw-500">
                    Disponible en
                </div>

                <div class="col-12 p-2 fw-200">
                    Ciudad de México
                </div>

                <div class="col-12 p-2 fw-200">
                    Zona Metropolitana
                </div>
            </div>
        </div>

          <div class="col-12 col-md-6 col-lg-3 mb-5">
              <div class="row">
                  <div class="col-12 p-2 fw-500">
                      Contacto
                  </div>

                  <div class="col-12 p-2 fw-200">
                      Teléfono: 55-5266-5387
                  </div>

                  <div class="col-12 p-2 fw-200">
                      Correo: {{ 'maltiadigital@maltia.com.mx' }}
                  </div>

                  <div class="col-12 p-2 fw-200">
                      Horario de Atención:
                      <br>
                      Lunes a Viernes
                      <br>
                      09:00 a.m. a 6:30 p.m.
                  </div>
              </div>
          </div>

          <div class="col-12 col-md-6 col-lg-3">
              <div class="row">
                  <div class="col-12 p-2 fw-500 mb-3">
                      Redes Sociales
                  </div>

                  <div class="col-12 d-flex mb-4">
                      <a 
                          class="btn btn-link text-decoration-none text-secondary bg-white rounded-circle d-flex align-items-center justify-content-center"
                          style="height: 35px !important; width: 35px !important"
                      >
                          <fa-icon [icon]="faFacebook"></fa-icon>
                      </a>

                      <div class="mx-2"></div>

                      <a 
                          class="btn btn-link text-decoration-none text-secondary bg-white rounded-circle d-flex align-items-center justify-content-center"
                          style="height: 35px !important; width: 35px !important"
                      >
                          <fa-icon [icon]="faInstagram"></fa-icon>
                      </a>

                      <!-- <div class="mx-2"></div>

                      <a 
                          class="btn btn-link text-decoration-none text-secondary bg-white rounded-circle d-flex align-items-center justify-content-center"
                          style="height: 35px !important; width: 35px !important"
                      >
                          <fa-icon [icon]="faTwitter"></fa-icon>
                      </a>

                      <div class="mx-2"></div>

                      <a 
                          class="btn btn-link text-decoration-none text-secondary bg-white rounded-circle d-flex align-items-center justify-content-center"
                          style="height: 35px !important; width: 35px !important"
                      >
                          <fa-icon [icon]="faTiktok"></fa-icon>
                      </a>

                      <div class="mx-2"></div>

                      <a 
                          class="btn btn-link text-decoration-none text-secondary bg-white rounded-circle d-flex align-items-center justify-content-center"
                          style="height: 35px !important; width: 35px !important"
                      >
                          <fa-icon [icon]="faYoutube"></fa-icon>
                      </a> -->

                  </div>

                  <!-- <div class="col-12 p-2 fw-500 mb-2">
                      Contacta con nuestros asesores
                  </div>

                  <div class="col-12 d-flex mb-5">
                      <div class="mr-2">
                          <img src="./assets/media/img/icons/Zoom_White 1.png" width="80%">
                      </div>

                      <div class="d-flex align-items-center">
                          <img src="./assets/media/img/icons/Zoom_White_V2 1.png" width="80%">
                      </div>
                  </div> -->

                  <div class="col-12 p-2 fw-500 mb-3">
                      Descarga nuestra app
                  </div>

                  <div class="col-12 d-flex">
                      <!--<div class="d-flex align-items-center">
                          <img src="./assets/media/img/icons/disponible_app_V1 1.png" width="100%">
                      </div>-->

                      <div class="mx-1"></div>

                      <a 
                        class="d-flex align-items-center"
                        target="_blank"
                        href="https://play.google.com/store/apps/details?id=com.maltia.maltiagza">
                          <img src="./assets/media/img/icons/disponible_play_V1 1.png" width="100%" alt="logo-play-store">
                      </a>
                  </div>

              </div>
          </div>

      </div>

  </div>
</footer>